var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Basic Map" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function () {
            return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "l-map",
        { attrs: { zoom: _vm.zoom, center: _vm.center } },
        [
          _c("l-tile-layer", { attrs: { url: _vm.url } }),
          _c("l-circle", {
            attrs: {
              "lat-lng": _vm.circle.center,
              radius: _vm.circle.radius,
              color: _vm.circle.color,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }