var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Custom Icon" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function () {
            return [_vm._v(" " + _vm._s(_vm.codeCustomIcon) + " ")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "l-map",
        { attrs: { zoom: _vm.zoom, center: _vm.center } },
        [
          _c("l-tile-layer", {
            attrs: { url: _vm.url, attribution: _vm.attribution },
          }),
          _c("l-marker", { attrs: { "lat-lng": [47.41322, -1.219482] } }),
          _c("l-marker", {
            attrs: { "lat-lng": [47.41322, -1.209482], icon: _vm.icon },
          }),
          _c(
            "l-marker",
            { attrs: { "lat-lng": [47.41322, -1.199482] } },
            [
              _c(
                "l-icon",
                [
                  _c("b-img", {
                    attrs: {
                      src: require("@/assets/images/misc/leaf-red.png"),
                      height: "50",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }