var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c("leaflet-basic"),
          _c("leaflet-marker-circle-polygon"),
          _c("leaflet-popup"),
          _c("leaflet-geo-jSON"),
          _c("leaflet-group"),
          _c("leaflet-custom-icon"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }